const base_url = process.env.VUE_APP_API_URL;
const GET_DEVICES_LISTING = base_url + "devices/listing";
const UPDATE_DEVICE = base_url + "devices/";
const CHECK_USER_PASSWORD = base_url + "users/check_password";
const DELETE_DEVICE = base_url + "devices/";
const DELETE_DEVICE_FROM_ADMIN = base_url + "devices/remove_from_admin";
const DELETE_DEVICE_FROM_PLATFORM = base_url + "devices/remove_from_platform/";
const GET_CUSTOMERS_LIST = base_url + "companies/list";
const GET_CUSTOMERS_LISTING = base_url + "companies/listing";
const CREATE_DEVICE_BY_CSV = base_url + "devices/csv";
const GET_BINS_LISTING = base_url + "bins/listing_admin";
const GET_BIN_LIST = base_url + "bins/list";
const GET_WASTE_TYPE = base_url + "bins/wastetypes";
const GET_UPLINK_LISTING = base_url + "devices/uplink_listing";
const ADD_DEVICES_DATA = base_url + "devices";
const ADD_REPO_TO_PLATFORM = base_url + "devices/repo_to_platform";
const EXPORT_DEVICES = base_url + "devices/expo";
const CSV_REPO = base_url + "devices/repo";
// Notifications
const ADD_NOTIFICATION_RULES = base_url + "notifications";
const GET_NOTIFICATION_LIST = base_url + "notifications/listing";
const GET_NOTIFICATION_EMAIL_LIST = base_url + "notifications/email/";
const DELETE_NOTIFICATION = base_url + "notifications/";
const ADD_NOTIFICATION_EMAIL = base_url + "notifications/email";
const DELETE_NOTIFICATION_EMAIL = base_url + "notifications/email/";
const GET_NOTIFICATION_RULES = base_url + "notifications/";
const ADD_NOTIFICATION_MOBILE = base_url + "notifications/mobile";
const GET_NOTIFICATION_MOBILE_LIST = base_url + "notifications/mobile/";
const DELETE_NOTIFICATION_MOBILE = base_url + "notifications/mobile/";

// Invoices
const GET_INVOICEING_LISTING = base_url + "invoices/listing";
const CREATE_INVOICE = base_url + "invoices/";
const UPDATE_INVOICE = base_url + "invoices/";
const GET_INVOICE = base_url + "invoices/";
const DELETE_INVOICE = base_url + "invoices/";
const GET_USERS_LIST = base_url + "users/listing";
const ADD_USERS = base_url + "users";
const DELETE_USER = base_url + "users/";
const UPDATE_USER = base_url + "users/";
const CHANGE_USER_PASSWORD = base_url + "users/change_password";
const ADD_COMPANIES = base_url + "companies";
const DELETE_COMPANIES = base_url + "companies/";
const UPDATE_COMPANY = base_url + "companies/";
const GET_COMPANY = base_url + "companies/";
const PRODUCTION_SCRIPT = base_url + "devices/production";
const CREDENTIAL_SHEETS_CSV_NAMES = base_url + "devices/csv";
// Downlinks
const GET_DOWNLINKS_LISTING = base_url + "downlinks/listing";
const CREATE_DOWNLINK = base_url + "downlinks/";
const UPDATE_DOWNLINK = base_url + "downlinks/";
const DELETE_DOWNLINK = base_url + "downlinks/delete";
const GET_DOWNLINK_DEVICES = base_url + "downlinks/listing_device";
const DECODE_HEX = base_url + "decoder/";
// Webhooks
const GET_WEBHOOKS = base_url + "webhooks";
const CREATE_WEBHOOKS = base_url + "webhooks/";
const UPDATE_WEBHOOKS = base_url + "webhooks/";
const DELETE_WEBHOOKS = base_url + "webhooks/";
//Roles and Permission
const ADD_NEW_ROLE = base_url + "auth/roles";
const USER_ROLE = base_url + "auth/roles";
const EDIT_ROLE = base_url + "auth/roles/";
const DELETE_ROLE = base_url + "auth/roles/";
const NEW_PERMISSION = base_url + "auth/permissions";
const PERMISSION_LIST = base_url + "auth/permissions/list";
const UPDATE_PERMISSION = base_url + "auth/permissions/";
const DELETE_PERMISSION = base_url + "auth/permissions/";
const ASSIGN_PERMISSION = base_url + "auth/assign_permission";
const MANAGE_PERMISSION = base_url + "auth/manage_permission";
const PERMISSION_BY_ROLE = base_url + "auth/permissions_by_role";
const UN_ASSIGN_PERMISSION = base_url + "auth/un_assign_permissions";
// Services
const SERVICES_LIST = base_url + "auth/services";
//Features
const FEATURE_LIST = base_url + "auth/features/list";
const NEW_ADD_FEATURE = base_url + "auth/features";
const UPDATE_FEATURE = base_url + "auth/features/";
const DELETE_FEATURE = base_url + "auth/features/";
//assign company waste type
const WASTE_TYPE_LIST = base_url + "companies/waste_types/list";
const ADD_WASTE_TYPE = base_url + "companies/waste_types";
const UPDATE_WASTE_TYPE = base_url + "companies/waste_types/";
const DELETE_WASTE_TYPE = base_url + "companies/waste_types/";
const ASSIGN_WASTE_TYPES = base_url + "companies/assign_waste_types";
const WASTE_TYPE_BY_COMPANY = base_url + "companies/waste_types_by_company";
//Csv decoder
const CSV_DECODER = base_url + "devices/decoder/";
//Bins operation update data
const DEPTH_MAP = base_url + "bins/admin/depth_map";
const Bin_Notification = base_url + "bins/admin/notification ";
const BINS_MOBILITY = base_url + "bins/admin/mobility";
const UPDATE_BIN_DIMENSION = base_url + "bins/bin_dimension";
const UPDATE_BIN_WASTE_TYPE_ID = base_url + "bins/bin_waste_type";
const UPDATE_BIN_LOCATION = base_url + "bins/bin_location";
const OFFSET = base_url + "bins/offset_raw_distance";
const UPDATE_FULL_AT = base_url + "bins/full_at";
const UPDATE_THRESHOLD = base_url + "companies/pickup/";
//Analysis Report
const DEVICE_DATA = base_url + "devices/report_data";
const COMPANIES_DATA = base_url + "companies/fetch_report_data";
// Routing Configuration
const ROUTING_CONFIGURATION = base_url + "routes/company";
const GET_ROUTING_LISTING = base_url + "routes/listing";
const DELETE_CONFIGURATION = base_url + "routes/configuration/";
const UPDATE_CONFIGURATION = base_url + "routes/configuration/";
const BINS_CONFIGURATION = base_url + "bins/configuration";
// Trackers
const GET_TRACKERS_LISTING = base_url + "trackers/listing_admin";
const DELETE_TRACKER_FROM_PLATFORM = base_url + "trackers/";
const UPDATE_TRACKER = base_url + "trackers/update/";

// KPI APIs
const GROUP_LIST = base_url + "companies/address_group/get";
const ADD_ADDRESS_GROUP = base_url + "companies/add_address_group";
const ADDRESS_LIST = base_url + "companies/address/get";
const ADD_ADDRESS = base_url + "companies/add_address";
const ADDRESS_DETAILS = base_url + "companies/address_detail/";

const URLS = {
  GET_DEVICES_LISTING,
  GET_CUSTOMERS_LIST,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  GET_WASTE_TYPE,
  CHECK_USER_PASSWORD,
  CREATE_DEVICE_BY_CSV,
  GET_BINS_LISTING,
  GET_BIN_LIST,
  GET_UPLINK_LISTING,
  ADD_DEVICES_DATA,
  ADD_REPO_TO_PLATFORM,
  GET_CUSTOMERS_LISTING,
  EXPORT_DEVICES,
  CSV_REPO,
  // Notifications
  ADD_NOTIFICATION_RULES,
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_EMAIL_LIST,
  ADD_NOTIFICATION_EMAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_EMAIL,
  GET_NOTIFICATION_RULES,
  GET_INVOICEING_LISTING,
  ADD_NOTIFICATION_MOBILE,
  GET_NOTIFICATION_MOBILE_LIST,
  DELETE_NOTIFICATION_MOBILE,
  // Invoices
  CREATE_INVOICE,
  UPDATE_INVOICE,
  GET_INVOICE,
  DELETE_INVOICE,
  GET_USERS_LIST,
  ADD_USERS,
  DELETE_USER,
  UPDATE_USER,
  CHANGE_USER_PASSWORD,
  ADD_COMPANIES,
  DELETE_COMPANIES,
  UPDATE_COMPANY,
  PRODUCTION_SCRIPT,
  CREDENTIAL_SHEETS_CSV_NAMES,
  DELETE_DEVICE_FROM_ADMIN,
  DELETE_DEVICE_FROM_PLATFORM,
  // downlinks
  GET_DOWNLINKS_LISTING,
  CREATE_DOWNLINK,
  UPDATE_DOWNLINK,
  DELETE_DOWNLINK,
  GET_DOWNLINK_DEVICES,
  DECODE_HEX,
  // Web hooks
  GET_WEBHOOKS,
  CREATE_WEBHOOKS,
  UPDATE_WEBHOOKS,
  DELETE_WEBHOOKS,
  //roles
  DELETE_ROLE,
  NEW_PERMISSION, 
  PERMISSION_LIST,
  NEW_ADD_FEATURE,
  FEATURE_LIST,
  ASSIGN_PERMISSION,
  UPDATE_FEATURE,
  DELETE_FEATURE,

  // Permissions
  USER_ROLE,
  ADD_NEW_ROLE,
  EDIT_ROLE,
  MANAGE_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  PERMISSION_BY_ROLE,
  UN_ASSIGN_PERMISSION,

  // Services
  SERVICES_LIST,
  //waste type
  WASTE_TYPE_LIST,
  ADD_WASTE_TYPE,
  UPDATE_WASTE_TYPE,
  DELETE_WASTE_TYPE,
  ASSIGN_WASTE_TYPES,
  WASTE_TYPE_BY_COMPANY,
  //CSV_DECODER
  CSV_DECODER,
  //Bins operation update data
  UPDATE_BIN_DIMENSION,
  UPDATE_BIN_WASTE_TYPE_ID,
  UPDATE_BIN_LOCATION,
  DEPTH_MAP,
  Bin_Notification,
  BINS_MOBILITY,
  OFFSET,
  UPDATE_FULL_AT,
  UPDATE_THRESHOLD,
  //Analysis Report
  DEVICE_DATA,
  COMPANIES_DATA,
  // Routing Configuration
  ROUTING_CONFIGURATION,
  GET_ROUTING_LISTING,
  DELETE_CONFIGURATION,
  UPDATE_CONFIGURATION,
  BINS_CONFIGURATION,
  // Trackers
  GET_TRACKERS_LISTING,
  DELETE_TRACKER_FROM_PLATFORM,
  UPDATE_TRACKER,
  // KPI APIs
  GROUP_LIST,
  GET_COMPANY,
  ADD_ADDRESS_GROUP,
  ADDRESS_LIST,
  ADD_ADDRESS,
  ADDRESS_DETAILS
};
export default URLS;
