import { getDevicesListing } from "../api/devices";

export const GET_DEVICES_LISTING = "GET_DEVICES_LISTING";
export const SET_DEVICES_LISTING = "SET_DEVICES_LISTING";
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_TOTAL_BINS = "SET_TOTAL_BINS";

export const devices = {
  namespaced: true,
  state: {
    devices: [],
    loading: false,
  },
  mutations: {
    [SET_DEVICES_LISTING]: (state, data) => {
      state.devices = data;
    },
    [SET_TOTAL_BINS]: (state, data) => {
      state.totalNumberOfBins = data;
    },
  },
  actions: {
    [GET_DEVICES_LISTING]: async ({ commit }, payload) => {
      // commit(SET_LOADING, true);
      // commit(SET_ERROR, null, { root: true });
      try {
        const res = await getDevicesListing(payload);

        // commit(SET_LOADING, false);
        commit(SET_DEVICES_LISTING, res.devices);
        // commit(SET_TOTAL_BINS, res.total_number_of_records);
      } catch (error) {
        // commit(SET_LOADING, false);
        console.log(error);
        // let message = error.response.data.message;
        // commit("SET_ERROR", message, { root: true });
      }
    },
  },
  getters: {
    getDeviceListing: (state) => {
      return state.devices;
    },
  },
};
