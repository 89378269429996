import { createApp } from "vue";
import AOS from "aos";
import App from "./App.vue";
import "aos/dist/aos.css";
import router from "./router";
import store from "./store";
import i18n from "@/core/plugins/i18n";
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";

// Date time picker/
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "@/core/plugins/prismjs";
import "bootstrap";
import "@popperjs/core";
import JsonCSV from "vue-json-csv";

const app = createApp(App);
app.use(BootstrapVue3);
app.use(store);
app.use(router);
app.component("Datepicker", Datepicker);
app.component("downloadCsv", JsonCSV);
app.use(AOS.init());

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(CKEditor);
app.use(ElementPlus);


app.mount("#app");
